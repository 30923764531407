.secciones {
    display:flex;
    background-color: white;
    height: auto;
}
.text {
    text-align: center;
    margin: 10px 5px;
    padding-bottom: 0px;
color:black;
letter-spacing: 2px;
}
.text h3{
    margin-top: 15px;
}
.text h2 {
    margin-top: 20px;
    font-size:larger;
}






article {
    
    display:flex;
    align-items:normal;
    width: 30%;
    height: 70%;
    margin: 15px;
    
    padding: 0;
    margin-bottom: 25px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 1.1)
}
article a{
    text-decoration: none;
    color: #777;
}
article img{
    width: 100%;
    height:100%;
    border-radius: 5px;
}
article:hover{
 -webkit-transform: scale(1.1);
 -ms-transform: scale(1.1);
 transform: scale(1.1);


}
article a:hover{
    text-decoration: none;
    color: #111;
}


.box {
    display: flex;
    flex-wrap:wrap;
    flex-direction:row;
align-content: normal;
    justify-content:center;
   
    align-items:center;
    width: auto;
    height:58% ;
}


main section article .descripcion {
    display: none;
}

main section article .descripcion .descrip {
    height: 0;
    transition: all ease .3s;
    overflow: hidden;
    color: var(--Gris oscuro);
    font-size: 16px;
}

main section article:hover .descripcion .descrip {
    height: 20px;
    margin-top: 10px;
}

main section article:hover {
    box-shadow: 0px 0px 10px rgb(100, 100, 100);
}

/**tablets**/
@media only screen and (max-width: 750px) {

.box {
    display: flex;
    flex-wrap:nowrap;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    width: auto;
    height: auto;
}
article {
    display:flex;
    align-items: start;
    width: 75%;
    height: 35%;
    margin: 15px;
    padding: 0;
}
.text {
    text-align: center;
    margin: 15px 15px;
    padding-bottom: 10px;
font-size: small;
color:gray;
letter-spacing: 2px;

}
}
