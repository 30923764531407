.cover-container {
  height: 100vh;
  width: 100%;
  object-fit: contain;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
}

.video {
  object-fit: cover;
  width: 100%;
  height: 100vh;

  position: absolute;
  z-index: -1;
}



.cover-container > h3 {
  margin-top: 8px;
  color:grey;

  text-align: center;
  
  margin-bottom: 200px;
}
.cover-container title:hover{
  color:azure;
 
}
.cover-container >h3:hover{
  color:white
}
title{
  font-family:Georgia, 'Times New Roman', Times, serif;

  display: flex;
 color:#2d0e0e;
  font-size: 30px;
  margin-bottom: 10px;
}

@media screen and (max-width: 760px) {
  
  .cover-container {
    height: 60vh;
    width: 100%;
  
  

  }
  .video {
    object-fit:fill;
    width:100%;
    height: 60vh;
  }
  .cover-container > h2 {
    font-size: 18px;
    text-align: center;
    margin-bottom: 15px;
   
    ;
  }

  .cover-container > h3 {
    display:none;
    text-align: center;
    font-size: 18px;
  
   
  }
  title{
   
    
    font-size: 16px;
    margin-bottom: 10px;
  }
}