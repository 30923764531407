.footer-container {
    width: 100%;
    height:60%;
    background-color:black;
    color: rgb(70, 65, 65);
    padding-top: 0px;
    position:static;
    z-index: -2;
 
    left: 0;
  }
  
  .footer-info {
    font-size: 20px;
    text-align: center;
    width: 70%;
    margin: 0 auto;
    margin-bottom: 45px;
    padding-top: 10px;
  }
  
  .footer-info > h6  {
    padding-bottom: 15px;
    font-size: 17px;
    text-align: center;
   color:white
  }
  .footer-info > p  {
    padding-bottom: 15px;
    font-size: 14px;
  }
  .footer-contact {
    font-size: 14px;
    width: 50%;
text-align: center;
    
  }

  
  .footer-sns {
    width: 50%;
    margin-bottom: 5px;
 
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    grid-template-columns: 2fr 1fr;
    align-items: center;

  }
  
  .sns-links {
    display: flex;
    justify-content:space-around;
   
    
    width: 100%;
    height: 40%;
    grid-template-columns: 1fr 1fr 1fr;
margin-bottom: 20px; 
margin-left: 120px; }
  
 .instagram,
  .facebook,
  .fa-envelope,
  .whatsapp {
    font-size: 40px;
    margin: 0 auto;
    padding-bottom: 25px;
    padding-top:25px;
    border-bottom: 4px solid transparent;
    
  }
 
  .instagram{
    color:gray;
  }
    
    .facebook{
      color:gray}
     
      .fa-envelope
     {
        color:gray }
        
        .whatsapp {
          color:#25D366}
  
  .facebook:hover,
  .instagram:hover,
  .fa-envelope:hover {
    transition: all 1s ease-out;
    color: #fff;
    transform: rotate(360deg);
  }
  .whatsapp:hover {
    transition: all 1s ease-out;
    color: black;
    transform: rotate(360deg);
  }
  .whatsapp {
    position:fixed;
    width:60px;
    height:60px;
    bottom:40px;
    right:40px;
    background-color:#25d366;
    color:#FFF;
    border-radius:50px;
    text-align:center;
    font-size:35px;
    z-index:100;
    padding-top:12px;
    margin: 0 auto;
    border-bottom: 4px solid transparent;
  
    
  }
  


  
  .design-by {
   margin-top:40PX;
    font-size: 16px;
    padding-bottom: 80px;
    color:white;
  }
  
  @media only screen and (max-width: 960px) {
    .footer-info {
      text-align: center;
      padding: 0;
      font-size: 16px;
    }
  
    .footer-contact {
      padding: 100px 0 30px 0;
      text-align: center;
    }
    .footer-contact h5{
font-size:8px;
    }
    .footer-sns {
      grid-template-columns: 1fr;
      text-align: center;
    }
  
    .design-by >p{
      display:flex;
      padding: 0 0 40px 0;
      font-size: 12px;
    
     
  }
    .facebook,
  .instagram,
  .fa-envelope,
  .whatsapp {
    font-size: 25px;
    margin: 0 auto;
    padding-bottom: 10px;
    border-bottom: 4px solid transparent;
  }
  .footer-info {
  
    text-align: center;
    width: 70%;
    margin: 0 auto;
   
    padding-top: 10px;
  }
    .footer-info > h6  {
   
    font-size: 14px;
    margin-top: 5px;
  }
  .sns-links {
    display: flex;
    justify-content:space-around;
    width: 100%;
    margin-left: 30px;

    grid-template-columns: 1fr 1fr 1fr;
margin-bottom: 10px;

  }
  .whatsapp {
    position:fixed;
    width:40px;
    height:40px;
    bottom:40px;
    right:40px;
    background-color:#25d366;
    color:#FFF;
    border-radius:50px;
    text-align:center;
    font-size:30px;
    z-index:100;
    padding-top:5px;
    margin: 0 auto;
    border-bottom: 4px solid transparent;
  
    
  }
  }
