.about-container {
  display: flex;
  background-color:white;
  color: #181D31;
  height: 100%;
  padding: 0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.about-desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 55%;
  padding:0 100px 0 100px;
  height:100%;
  
}

.about-desc > h3 {
  text-align:center;
  margin-bottom: 15px;
  font-size: 20px;
  color: #000; 
  margin-top: 10px;
}
.about-desc > p {
  text-align:center;
  margin-bottom: 10px;
  font-size: 16px;
  color:light;
  line-height: 1.5;
}


.about-img {
  width: 100%;
  height:auto;
  display: flex;
  align-self:auto;
  overflow:hidden;
  
  margin-top:10px
}
.about-desc h3:hover {
  color:gray;
  }
.about-desc p:hover {
color:#181D31;
}

@media only screen and (max-width: 960px) {
  .about-container {
    flex-direction: column;
    padding: 0;
    height: auto;
  }

  .about-desc {
    width: 90%;
    margin: 0 auto;
    padding: 40px 20px 50px 20px;
  }

  .about-desc > h3 {
    font-size: 20px;
    text-align: center;
  }

  .about-desc > p {
    font-size: 16px;
    text-align: center;
  }
  .about-img {
    width: 100%;
  
    margin-bottom: 50px;
  }

  .about-img > img {
    width: 100%;
  }
}