.mailer-container {
    display:flex;
    margin-bottom: auto;
    padding: 0;
    background-color:  #f4f4f4;
  align-items: center;
    width:100%;
  height: auto;
    flex-direction: row;
  align-content: space-around;
  flex-wrap: nowrap;
    position: relative;
align-items: flex-start;  
 
  .contacto{
    margin-top: 20px;
    text-align: center;
  }
  .contacto h3{
    margin-top: 30px;
    text-align: center;
    margin-bottom: 15px;
    
  }
  .contacto h4{
    text-align: center;
    color: #777;
    margin-bottom: 15px;
    font-weight: normal;
    margin-top: 30px;

  }
  .contacto p a{
   text-decoration: none;
   color:black;
   margin-left: 10px;
  }
  .contacto >p{
    text-align: center;
   
    color:black;
  
  }
  .contacto i{
    font-size:medium;
    color: gray;
    margin-top: 10px;
    margin-right: 10px;
    align-items: left;
  }
  .mailer-container .contacto i:hover{
    width: 22px;
    color:black
  }
  @media only screen and (max-width: 960px) {
    h1 {
      color: black;
      font-size: 14px;
      font-weight: 0;
      font-family: 'arial';
      margin: 0;
    }
    .form-container{
      width: 80%;
    }
    .form-mail {
      width: auto;
      border: groove 3px #ffff;
      border-radius:none;
     
    size-adjust:inherit;
      opacity: 0.8;  
      padding: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
     
    }
    textarea {
      
      padding: 0;
      height: 50px;
      width: 90%;
    }
    hr {
      width: auto;
    }
    .mailer-container {
     display:contents;
    }
  }}