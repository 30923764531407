* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Georgia, 'Times New Roman', Times, serif;

}
.map-wrapper {
  display: flex;
 justify-content: center;
  align-items: center;

}
.NIKCg{
  margin:auto;
 

}
.NIKCg .MenuItem:hover{
  color:#25d366
}
.scroll-to-top i{
   position:fixed;
font-size: 32px;
color:gray
;
margin-left: 40px;
bottom: 40px;
}
.scroll-to-top i:hover{
   cursor: pointer;
  
   color:#fff
   
    }
   
   